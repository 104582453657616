import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import * as Styled from './slpTmTopHeaderStyle';

const SlpTmTopHeader = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.TitleContainer $backgroundImage={sectionData?.image?.file?.url}>
      <Styled.Title>
        {sectionData?.richText &&
          documentToReactComponents(
            JSON.parse(sectionData?.richText?.raw),
            optionsMainStyle
          )}
      </Styled.Title>
    </Styled.TitleContainer>
  );
};

export default SlpTmTopHeader;

import styled from 'styled-components';

export const TitleContainer = styled.div`
  height: 640px;
  background: #fff url(${(props) => props.$backgroundImage}) repeat-x top center;
  color: #6e2b8b;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const Title = styled.div`
  width: 800px;
  height: auto;
  margin: 0 auto;
  padding: 50px 0px 0px 0px;
  text-align: center;
  @media (max-width: 991x) {
    width: 100%;
    height: auto;
    padding: 10px 5% 10px 5%;
    text-align: center;
  }
`;

export const ImageStyle = styled.img`
  width: 650px;
  height: auto;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    width: 95%;
    height: auto;
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;
